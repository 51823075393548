import { Component } from 'react';
import type { ReactNode } from 'react';

import logger from 'saddlebag-logger';

import { namespace } from '../../constants';

export type ErrorBoundaryProps = {
  children: ReactNode;
  componentName: string;
  metadata?: object;
};

type ErrorBoundaryState = {
  hasError?: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps = {
    metadata: {},
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: any) {
    this.setState({ hasError: true });

    const stackInfo = info ? info.componentStack : '';
    logger.logError(error, {
      component: `${namespace}.${
        this.props.componentName || 'UnknownComponent'
      }`,
      message: `Error caught by error boundary: ${error.toString()}\n${stackInfo}`,
      ...this.props.metadata,
    });
  }

  render() {
    if (this.state.hasError) {
      return <div />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
