import { useEffect } from 'react';

import InternalLinkingGC from '@skyscanner-internal/global-components/internal-links';

import { ACTION_TYPE, LOAD_STATUS, COMPONENT_NAME } from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import withErrorBoundary from '../ErrorBoundary';
import IntersectionObserverWrapper from '../IntersectionObserverWrapper/IntersectionObserverWrapper';

import type { InternalLinksProps } from '@skyscanner-internal/global-components/internal-links';

import STYLES from './InternalLinksGCStyles.module.scss';

const InternalLinking = (props: InternalLinksProps) => {
  const { tabGroups, title } = props;

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.INTERNAL_LINKS,
      load_status:
        !title || !tabGroups?.length ? LOAD_STATUS.INVALID : LOAD_STATUS.LOADED,
    });
  }, [title, tabGroups]);

  if (!title || !tabGroups?.length) {
    return null;
  }

  return (
    <div className={STYLES.Content}>
      <InternalLinkingGC {...props} />
    </div>
  );
};

const InternalLinksWrapper = (props: InternalLinksProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.INTERNAL_LINKS,
      })
    }
  >
    <InternalLinking {...props} />
  </IntersectionObserverWrapper>
);

const InternalLinksWithErrorBoundary = withErrorBoundary(
  InternalLinksWrapper,
  'internal-links-component',
);

export default InternalLinksWithErrorBoundary;
