import { Component, createRef } from 'react';
import type { ReactElement, ReactNode } from 'react';

import { GLOBAL } from 'saddlebag-browser';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import STYLES from './IntersectionObserverWrapper.scss';

const cls = cssModules(STYLES);

type Props = {
  threshold?: number;
  onElementSeen: Function;
  children: ReactElement;
  onObserverUnavailable?: Function;
  ioWrapperClassName?: string;
};

const defaultProps = {
  threshold: 0.3,
  onObserverUnavailable: () => {},
  ioWrapperClassName: '',
};

class IntersectionObserverWrapper extends Component<Props> {
  ref: { current: HTMLDivElement | null };

  observer: IntersectionObserver;

  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.ref = createRef();
    this.observer = this.getObserver();
  }

  componentDidMount() {
    if (this.observer && this.ref.current) {
      this.observer.observe(this.ref.current);
    }
  }

  getObserver: () => IntersectionObserver = () => {
    const $window = GLOBAL.getWindow();
    if ($window) {
      if (typeof $window.IntersectionObserver === 'function') {
        const delayTime = 500;
        const observerOptions = {
          threshold: this.props.threshold,
          delay: delayTime,
        };

        let observeTimeout: NodeJS.Timeout | null = null;
        const observer = new $window.IntersectionObserver(
          (
            entries: Array<
              IntersectionObserverEntry & {
                isVisible: boolean | undefined;
              }
            >,
          ) => {
            entries.forEach((entry) => {
              // The browser doesn't support Intersection Observer v2, falling back to v1 behavior.
              if (typeof entry.isVisible === 'undefined') {
                if (
                  entry.intersectionRatio >=
                    (this.props.threshold || defaultProps.threshold) &&
                  entry.isIntersecting
                ) {
                  observeTimeout = setTimeout(
                    () => this.props.onElementSeen(),
                    delayTime,
                  );
                }
                if (!entry.isIntersecting && observeTimeout) {
                  clearTimeout(observeTimeout);
                }
              } else if (
                entry.isIntersecting &&
                entry.intersectionRatio >=
                  (this.props.threshold || defaultProps.threshold)
              ) {
                this.props.onElementSeen();
              }
            });
          },
          observerOptions,
        );
        return observer;
      }
      this.props.onObserverUnavailable?.();
    }

    return null;
  };

  render(): ReactNode {
    const { children, ioWrapperClassName } = this.props;
    return (
      <div
        ref={this.ref}
        className={cls(STYLES['io-wrapper'], ioWrapperClassName)}
      >
        {children}
      </div>
    );
  }
}

export default IntersectionObserverWrapper;
